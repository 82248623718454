import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'components/Link';
import Chip from 'components/Chip';
import { AppContext } from 'context';
import App from 'modules/App';
import Country from 'modules/Country';
import Account from 'modules/Account';
import messages from '../../../messages';
import styles from './Sidebar.pcss';
import UserBox from './UserBox';


class Sidebar extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    route                      : App.shapes.route,
    environmentName            : PropTypes.oneOf(['dev', 'test', 'stg', 'pre', 'prd']),
    navItems                   : PropTypes.array,
    isNavItemActive            : PropTypes.func,
    // Implicit actions
    hasActiveViewUnsavedChanges: PropTypes.bool,
    onOpenUnsavedChangesModal  : PropTypes.func,
  };

  onNavItemClick(e, item) {
    if (this.props.hasActiveViewUnsavedChanges) {
      this.props.onOpenUnsavedChangesModal();
      e.preventDefault();
    } else if (item && item.onClick) {
      item.onClick(e);
    }
  }


  getUrlTo(item) {
    if (!item.routeName) {
      return '';
    }
    return this.context.getUrl(item.routeName);
  }

  renderHeaderBox() {
    return (
      <div className={`col-auto ${styles.sidebar__headerContainer}`}>
        <div>
          <div className={styles.sidebar__headerContainer__top}>
            <h3 className="text--h3"><FormattedMessage {...messages.sidebarHeader.backOffice} /></h3>
            <Chip labelMessage={messages.sidebarHeader.env[this.props.environmentName]} styleModifier="inactive" />
          </div>
        </div>
        <p className={cn('text--small', 'text--disabled')}>
          <FormattedMessage {...messages.sidebarHeader.adminPanel} />
        </p>
      </div>
    );
  }


  renderNavItem(item) {
    const to = this.getUrlTo(item);
    return (
      <li
        key={item.key}
        className={cn(styles.sidebar__nav__list__item, {
          [styles['sidebar__nav__list__item--active']]: this.props.isNavItemActive(item),
        },
        styles[`sidebar__nav__list__item--${item.key}`]
        )}
      >
        <Link to={to} onClick={(e) => this.onNavItemClick(e, item)}>
          { item.Icon && <item.Icon className="mr-5" /> }
          <FormattedMessage {...messages.headers[item.key]} />
        </Link>
      </li>
    );
  }


  renderNav() {
    return (
      <nav className={`col ${styles.sidebar__nav}`}>
        <ul className={styles.sidebar__nav__list}>
          {this.props.navItems.map((item) => this.renderNavItem(item))}
        </ul>
      </nav>
    );
  }


  renderUserBox() {
    return (
      <div className="col-auto">
        <UserBox />
      </div>
    );
  }


  render() {
    return (
      <div className={styles.wrapperSidebar}>
        <aside className={styles.sidebar}>
          { this.renderHeaderBox() }
          { this.renderNav() }
          { this.renderUserBox() }
        </aside>
      </div>

    );
  }

}


const mapStateToProps = (state) => ({
  route                      : App.selectors.route(state),
  environmentName            : window.App.environmentName,
  hasActiveViewUnsavedChanges: Country.selectors.hasActiveViewUnsavedChanges(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenUnsavedChangesModal: () => dispatch(App.actions.openModal(App.constants.UNSAVED_CHANGES_MODAL)),
  onSignOut                : () => dispatch(Account.actions.signOut()),
});


const ConnectedSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);


export default withStyles(styles)(ConnectedSidebar);
