import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';
import messages from '../../messages';
import styles from './PlainLayout.pcss';


class PlainLayout extends React.Component {

  static propTypes = {
    // Explicit props
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
    // Implicit props
    children              : PropTypes.node,
    // Implicit actions
  };


  renderPageContent() {
    return (
      <div className={cn('col', styles.container)}>
        <div className={styles.containerInner}>
          <div className="content">
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };


    return (
      <App.components.LanguageProvider>
        <div
          className={cn(styles.root, 'no-gutters')}
        >
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          { this.renderPageContent() }
        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ConnectedPlainLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlainLayout);


export default withStyles(styles)(ConnectedPlainLayout);
