import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import { scrollToHash } from 'helpers/urlTools';
import App from 'modules/App';
import Account from 'modules/Account';
import messages from '../../messages';
import { NAV_ITEMS_ICONS } from '../../constants';
import Sidebar from './Sidebar';
import styles from './DashboardLayout.pcss';


class DashboardLayout extends React.Component {

  static propTypes = {
    // Explicit props
    children              : PropTypes.node,
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
    // Implicit props
    route                 : App.shapes.route,
    routings              : PropTypes.array,
    isClientInitialized   : PropTypes.bool,
  };


  constructor(props) {
    super(props);
    this.mainContentEl = null;
    this.state = { isInit: false };
  }


  componentDidMount() {
    if (this.props.isClientInitialized) {
      this.onSetIsInit();
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if ((prevState.isInit !== this.state.isInit && this.state.isInit)) {
      scrollToHash(this.props.route, 0, 0, this.mainContentEl);
    }
    if (prevProps.isClientInitialized !== this.props.isClientInitialized && this.props.isClientInitialized) {
      this.onSetIsInit();
    }
  }


  onSetIsInit() {
    this.setState({ isInit: true });
  }


  get navItems() {
    return map(this.props.routings, (item) => ({
      ...item,
      Icon: NAV_ITEMS_ICONS[item.key],
    }));
  }


  getIsActive = (navItem) => navItem.routeName === this.props.route.name


  renderContent() {
    return (
      <div id="mainContent" className={styles.content} ref={(el) => { this.mainContentEl = el; }}>
        { this.props.children }
      </div>
    );
  }


  render() {
    if (!this.state.isInit) return null;

    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };

    return (
      <App.components.LanguageProvider>
        <div className={cn(styles.root, 'no-gutters')}>
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <Sidebar
            navItems={this.navItems}
            isNavItemActive={this.getIsActive}
          />
          { this.renderContent() }
        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapStateToProps = (state) => ({
  route              : App.selectors.route(state),
  routings           : Account.selectors.authorizedRoutingsByScope(state),
  isClientInitialized: App.selectors.isClientInitialized(state),
});

const ConnectedDashboardLayout = connect(
  mapStateToProps,
)(DashboardLayout);


export default withStyles(styles)(ConnectedDashboardLayout);
