import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import AvatarIcon from 'svg/avatar.svg';
import Account from 'modules/Account';
import messages from '../../../../messages';
import styles from './UserBox.pcss';


class UserBox extends React.PureComponent {

  static propTypes = {
    information: PropTypes.shape({
      scope   : PropTypes.string,
      username: PropTypes.string,
    }),
  };


  render() {
    const { username, scope } = this.props.information || {};
    const [usernameShortName] = username.split('@');
    return (

      <div
        className="d-flex mt-7"
        title={username}
      >
        <div className="pr-3">
          <AvatarIcon />
        </div>
        <div className="pl-3">
          <p className={cn(styles.userBox__email, 'text--large text--light mb-2')}>{usernameShortName}</p>
          <p className="text--normal text--small text--disabled">
            {scope && <FormattedMessage {...messages.sidebarHeader.scope[scope]} />}
          </p>
        </div>
      </div>

    );
  }

}


const mapStateToProps = (state) => ({
  information: Account.selectors.account(state),
});

const ConnectedUserBox = connect(
  mapStateToProps,
)(UserBox);

export default withStyles(styles)(ConnectedUserBox);
