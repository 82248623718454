import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';
import messages from '../../messages';
import styles from './ErrorLayout.pcss';


class ErrorLayout extends React.Component {

  static propTypes = {
    // Explicit props
    children              : PropTypes.node,
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
  };


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };


    return (
      <App.components.LanguageProvider>
        <div className={cn(styles.root, 'no-gutters')}>
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <div className="col-auto d-lg-none">
            <div className={styles.logoBar}>
              <img src="/bo/assets/svg/logo.svg" className={styles.logoBar__logo} alt="Ascensia Diabetes Care" />
            </div>
          </div>
          <div className={cn('col', styles.container)}>
            <div className={styles.containerInner}>
              <div className="content content--fixed">
                { this.props.children }
              </div>
            </div>
          </div>
        </div>
      </App.components.LanguageProvider>
    );
  }

}


export default withStyles(styles)(ErrorLayout);
